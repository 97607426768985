import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    timepointStorageTag: String
  }
  static get targets() {
    return [
      "timeField"
    ]
  }

  connect(){
    this.setTimepointTime()
    window.initializeTimepickers()
    $(".datepicker").datepicker();

    document.addEventListener("turbo:frame-missing", (event) => {
      const { detail: { response, visit } } = event;
      event.preventDefault();
      visit(response.url);
    });

    $('input[type="number"]').each((index,field)=>{
      $(field).inputSpinner()
    })

    $(".timepicker").timepicker({
      showMeridian: false,
      showSeconds: true,
      minuteStep: 10,
      secondStep: 10,
      icons: {
      up: "fas fa-chevron-up",
      down: "fas fa-chevron-down",
      },
    });
  }

  checkCollectionDate(e) {
    let collection_date = e.target.value.toLowerCase();
    let visit_date = e.target.getAttribute("data-visit-date").toLowerCase();

    if(collection_date !== visit_date) {
      $('.date-mismatch-alert').removeClass('d-none');
    } else {
      $('.date-mismatch-alert').addClass('d-none');
    }
  }

  toggleCollapseIcon(e){
    var step_id = e.target.getAttribute("data-step-id")
    $(`#toggle-icon-${step_id}`).toggleClass('fa-chevron-up');
    $(`#toggle-icon-${step_id}`).toggleClass('fa-chevron-down');
  }

  toggleConsentModalTrigger(e) {
    // switch the normal Mark Complete button with a lookalike that triggers the modal 
    // if the user selected the No radio button >:)
    if($(e.target).prop('checked') && $(e.target).val() == 2) {
      $(".consent-complete").addClass("d-none");
      $(".consent-modal").removeClass("d-none");
    } else {
      $(".consent-complete").removeClass("d-none");
      $(".consent-modal").addClass("d-none");
    }
  }

  toggleProcedureCollectedStatus(event){
    $(".procedure-not-collected-select").on('change', function(e){
      var timepoint_id = e.target.getAttribute("data-timepoint-id")
      var step_id = e.target.getAttribute("data-step-id")
      if($(e.target).prop('checked')){
        $(e.target).closest(".procedure-step").find(".notes-container").removeClass("d-none");
        $(e.target).closest(".procedure-step").find(".notes-field").attr("disabled", false)
        $(`#collection_date_${step_id}`).removeClass("d-none");
        $(`#step_time_${step_id}`).removeClass("d-none");
        $(`#step_time_${step_id}`).prop('required', true);
        $(`label[for='timepoint_${timepoint_id}_collection_status']`).text("Collected")
        $('.collection-not-collected-reason').addClass('d-none');
        $('#reason-not-collected-select').prop('required', false);
        $('.time-label').removeClass('d-none');
        $('.date-label').removeClass('d-none');
        $('.time-zone-indicator').removeClass('d-none');

        $(".not-collected-select").each(function(i) {
          $(this).prop("checked", true)
          $(`label[for='${$(this).attr("name")}']`).text("Collected")
        })

        $(`.autocomplete-barcode-field`).attr("disabled", false);
        $(`.autocomplete-barcode-field`).attr("placeholder", "Scan or type");

        $(`.timepoint-meta-field[data-timepoint-id='${timepoint_id}']`).attr("disabled", false);

        $(".mark-complete").each(function(i) {
          if ($(this).prop("disabled")) {
            $(this).removeClass("d-none")
          }
        })
      } else {
        $(e.target).closest(".procedure-step").find(".notes-container").addClass("d-none");
        $(e.target).closest(".procedure-step").find(".notes-field").attr("disabled", true)
        $(`#collection_date_${step_id}`).addClass("d-none");
        $(`#step_time_${step_id}`).addClass("d-none");
        $(`#step_time_${step_id}`).prop('required', false);
        $(`label[for='timepoint_${timepoint_id}_collection_status']`).text("Not collected")
        $('.collection-not-collected-reason').removeClass('d-none');
        $('#reason-not-collected-select').prop('required', true);
        $('.time-label').addClass('d-none');
        $('.date-label').addClass('d-none');
        $('.time-zone-indicator').addClass('d-none');

        $(".not-collected-select").each(function(i) {
          $(this).prop("checked", false)
          $(`label[for='${$(this).attr("name")}']`).text("Not collected")
        })

        $(`.autocomplete-barcode-field`).attr("disabled", true);
        $(`.autocomplete-barcode-field`).attr("placeholder", "Not Collected");
        $(`.autocomplete-barcode-field`).val("");

        $(".mark-complete").each(function(i) {
          if ($(this).prop("disabled")) {
            $(this).addClass("d-none")
          }
        })
      }
    });
  }

  storeTimepointTime(event){
    var tag = this.timepointStorageTagValue
    var existing_time = localStorage.getItem(tag)
    if (existing_time == null && this.hasTimeFieldTarget){
      var time = this.timeFieldTarget.value
      localStorage.setItem(tag, time)
    }
  }

  setTimepointTime(event){
    var tag = this.timepointStorageTagValue
    var suggested_time = localStorage.getItem(tag)
    if (suggested_time !== null && this.hasTimeFieldTarget){
      this.timeFieldTarget.value = suggested_time
    }
  }
}
